const Instagram = () => {
  return (
    <section className="bg-gray-900 flex items-center justify-center h-screen" id="instagram">
      <iframe src="https://www.instagram.com/hughesmayball/embed" 
        className="rounded-lg h-full sm:w-full md:w-3/4 lg:w-1/2"
        title="Instagram"
      >
      </iframe>
    </section>
  )
}

export default Instagram;